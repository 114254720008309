import * as _ from 'lodash';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterReducerState} from '@ngrx/router-store';

import {selectRouterState} from '../../core/selectors';
import {AuthState} from '../reducers';
import {State} from '../reducers/auth';
import {RouterStateUrl} from '../../shared/utils';
import {Project} from '../../../../common/project.model';
import {Permission, PermissionMap, PermissionType, ProjectPermissions, UserEnrollmentCondition, UserIsInRole} from '../../../../common/user-profile.model';
import {ProjectSettingTab, ProjectSettingTabs} from '../../settings/models/project-settings-view.model';
import {AccountCodeSegmentConfig, ProjectSetting} from '../../../../common/project-setting.model';
import {InternalUserRole, TimecardTypes} from '../../../../common/timecard.model';
import {ClientConfig} from '../../../../common/client-config.model';
import {DayOfWeek, Region} from '../../../../common/constants';
import {BatchBrowseConstants} from '../../../../constants/batch-browse.constants';
import {UiState} from '../../reducers/ui.reducer';
import {Department} from '../../../../common/department-setting.model';
import {User} from '../../../../common/user.model';

const _selectIsLoggedIn = (state: State) => !!state.user;
const _selectUserFirstName = (state: State) => _.get(state, 'user.firstName', '');
const _selectUserRole = (state: State) => _.get(state, 'user.role', '');
const _selectUserDisplayName = (state: State) => _.get(state, 'user.displayName', '');
const _selectUserEmail = (state: State) => _.get(state, 'user.email', '');
const _selectProjects = (state: State) => state.projects;
const _selectProjectPermissions = (state: State) => state ? state.projectPermissions : null;
const _selectProjectPaymaster = (state: ProjectSetting) => state ? state.paymaster : null;
const _selectProjectClientId = (state: ProjectSetting) => state ? state.clientId : null;
const _selectPermissions = (state: ProjectPermissions) => state ? state.permissions : null;
const _selectProjectUserWorkflowIds = (state: ProjectPermissions) => state ? state.workflowRoleIds : [];
const _selectAccountCodingSegments = (state: ProjectSetting) => state ? state.accountCodeConfig.segments : [];

const _selectSelectedProject = (authState: State, routeState: RouterReducerState<RouterStateUrl>): Project | null => {
  const projectId: string = _.get(routeState, 'state.projectId', '');
  if (!projectId) {
    return null;
  }
  return _
    .chain(authState.projects)
    .filter((project: Project) => {
      return project.projectId === projectId;
    })
    .head()
    .value();
};
const _selectUser = (state: State) => state.user;

const _selectProjectSettingTabs = (state: PermissionMap): ProjectSettingTab[] => {
  const isProjectTabDisabled = state[PermissionType.PROJECT_OPTIONS] === Permission.NONE
    && state[PermissionType.PROJECT_DETAILS] === Permission.NONE;
  const isAccountCodingTabDisabled = state[PermissionType.ACCOUNT_CODING] === Permission.NONE;
  const isDepartmentsTabDisabled = state[PermissionType.DEPARTMENT_MANAGEMENT] === Permission.NONE;
  const isContractsTabDisabled = state[PermissionType.CONTRACTS] === Permission.NONE;
  const isApprovalsTabDisabled = state[PermissionType.APPROVAL_MANAGEMENT] === Permission.NONE;
  return [
    {name: ProjectSettingTabs.PROJECT_DETAILS, path: './project-details', qaa: 'projectDetailsLabel', isDisabled: isProjectTabDisabled},
    {name: ProjectSettingTabs.ACCOUNT_CODING, path: './account-coding', qaa: 'accountCodingLabel', isDisabled: isAccountCodingTabDisabled},
    {name: ProjectSettingTabs.GL_SHORTCUTS, path: './gl-shortcuts', qaa: 'glShortcutsLabel', isDisabled: isAccountCodingTabDisabled},
    {name: ProjectSettingTabs.DEPARTMENTS, path: './departments-details', qaa: 'departmentDetailsLabel', isDisabled: isDepartmentsTabDisabled},
    {name: ProjectSettingTabs.CONTRACTS, path: './contracts-details', qaa: 'contractsLabel', isDisabled: isContractsTabDisabled},
    {name: ProjectSettingTabs.APPROVAL_WORKFLOW, path: './approval-workflow', qaa: 'approvalLabel', isDisabled: isApprovalsTabDisabled},
    {name: ProjectSettingTabs.SECURITY, path: './security', qaa: 'securityLabel', isDisabled: false}
  ];
};

const _selectProjectDetailPermissions = (state: PermissionMap) => {
  const isButtonsEnabled = state[PermissionType.PROJECT_OPTIONS] === Permission.FULL
    || state[PermissionType.PROJECT_DETAILS] === Permission.FULL;
  const isOptionsEditForbidden = state[PermissionType.PROJECT_OPTIONS] !== Permission.FULL;
  const isDetailsEditForbidden = state[PermissionType.PROJECT_DETAILS] !== Permission.FULL;
  return {isButtonsEnabled, isOptionsEditForbidden, isDetailsEditForbidden};
};

const _selectIsWorkflowEnabled = (state: ProjectSetting) => {
  return state && state.workflowEnabled && (!_.isEmpty(state.processDefinitionId));
};

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state: AuthState) => state ? state.status : null
);
export const selectUserFirstName = createSelector(
  selectAuthStatusState,
  _selectUserFirstName
);
export const selectUserRole = createSelector(
  selectAuthStatusState,
  _selectUserRole
);
export const selectIsPaymaster = createSelector(
  selectUserRole,
  (role: string) =>
    _.toUpper(role) === InternalUserRole.PAY_MASTER || _.toUpper(role) === InternalUserRole.PAY_MASTER_MANAGER
);
export const selectUserDisplayName = createSelector(
  selectAuthStatusState,
  _selectUserDisplayName
);
export const selectUserEmail = createSelector(
  selectAuthStatusState,
  _selectUserEmail
);
export const selectIsLoggedIn = createSelector(
  selectAuthStatusState,
  _selectIsLoggedIn
);
export const selectProjects = createSelector(
  selectAuthStatusState,
  _selectProjects
);
export const selectSelectedProjectId = createSelector(
  selectRouterState,
  (routeState: RouterReducerState<RouterStateUrl>) => routeState ? routeState.state.projectId : ''
);
export const selectSelectedProject = createSelector(
  selectAuthStatusState,
  selectSelectedProjectId,
  (state: State, projectId: string) => !_.isEmpty(projectId) ? state.selectedProject : null
);
export const selectUser = createSelector(
  selectAuthStatusState,
  _selectUser
);
export const selectClientDetails = createSelector(
  selectSelectedProject,
  (selectedProject) => ({
    clientId: _.get(selectedProject, 'projectNumber', ''),
    clientName: _.get(selectedProject, 'projectName', ''),
  })
);
export const selectIsPayMasterManager = createSelector(
  selectUserRole,
  (role: string): boolean => _.toUpper(role) === InternalUserRole.PAY_MASTER_MANAGER
);
export const selectProjectPermissions = createSelector(
  selectAuthStatusState,
  _selectProjectPermissions
);
export const selectIsVaEnabled = createSelector(
  selectAuthStatusState,
  (state: State): boolean => state ? state.isVaEnabled : false
);
export const selectPermissions = createSelector(
  selectProjectPermissions,
  _selectPermissions
);
export const selectProjectUserWorkflowIds = createSelector(
  selectProjectPermissions,
  _selectProjectUserWorkflowIds
);
export const selectProjectRole = createSelector(
  selectProjectPermissions,
  (projectPermission: ProjectPermissions) => projectPermission ? projectPermission.role : ''
);
export const selectProjectRoleId = createSelector(
  selectProjectPermissions,
  (projectPermission: ProjectPermissions) => projectPermission ? projectPermission.roleId : ''
);
export const selectPermission = (permissionType: PermissionType) => createSelector(
  selectPermissions,
  (state: PermissionMap) => {
    return state && state[permissionType] ? state[permissionType] : Permission.NONE;
  }
);

export const selectIsRouteForbidden = (permissionTypes: PermissionType[]) => createSelector(
  selectPermissions,
  (state: PermissionMap) => {
    return _.some(permissionTypes, (permissionType: PermissionType) => state[permissionType] !== Permission.NONE);
  }
);

export const selectProjectSettingTabs = createSelector(
  selectPermissions,
  _selectProjectSettingTabs
);
export const selectProjectDetailPermissions = createSelector(
  selectPermissions,
  _selectProjectDetailPermissions
);
export const selectIsInternalUser = createSelector(
  selectUserEmail,
  (email) => _.includes(email, '@ep.com')
);
const _selectUiRootState = createFeatureSelector<UiState>('ui');
export const selectProjectSettings = createSelector(
  selectAuthStatusState,
  _selectUiRootState,
  (state: State, {activeTimecardType}): ProjectSetting => {
    if (state) {
      return {
        ...state.projectSettings,
        payrollWeekDisplay: activeTimecardType === TimecardTypes.SAG
          ? DayOfWeek.Monday
          : state?.projectSettings?.payrollWeekDisplay
      };
    }
    return null;
  }
);

export const selectProjectPaymaster = createSelector(
  selectProjectSettings,
  _selectProjectPaymaster
);

export const selectProjectClientId = createSelector(
  selectProjectSettings,
  _selectProjectClientId
);

export const selectComputedWorkflowEnabled = createSelector(
  selectProjectSettings,
  _selectIsWorkflowEnabled
);
export const selectWorkflowEnabled = createSelector(
  selectProjectSettings,
  (state: ProjectSetting) => state?.workflowEnabled
);
export const selectAccountCodeConfigSegments = createSelector(
  selectProjectSettings,
  _selectAccountCodingSegments
);
export const selectIsPayrollTrackingEnabled = createSelector(
  selectAuthStatusState,
  ({user: {isPayrollTrackingEnabled}}: State) => isPayrollTrackingEnabled ?? false
);

export const selectIsTimecardOCREnabled = createSelector(
  selectAuthStatusState,
  ({user: {isTimecardOCREnabled}}: State) => isTimecardOCREnabled ?? false
);

export const selectIsChatEnabled = createSelector(
  selectAuthStatusState,
  ({user: {isChatEnabled}}: State) => isChatEnabled ?? false
);

export const selectIsUploadTCVisible = createSelector(
  selectIsPaymaster,
  selectIsTimecardOCREnabled,
  (isPaymaster: boolean, isOCREnabled: boolean) => isPaymaster && isOCREnabled
);

export const selectIsPermissionFull = (permissionType: PermissionType) => createSelector(
  selectPermissions,
  (state: PermissionMap): boolean => {
    return state && state[permissionType] === Permission.FULL;
  }
);

export const selectUserEnrollmentConditions = createSelector(
  selectProjectPermissions,
  (state: ProjectPermissions): UserEnrollmentCondition[] => state?.userEnrollmentConditions ?? []
);

export const selectIsUserEnrollmentPending = createSelector(
  selectIsInternalUser,
  selectUserEnrollmentConditions,
  (isInternalUser: boolean, userEnrollmentConditions: UserEnrollmentCondition[]): boolean =>
    !isInternalUser && (_.isEmpty(userEnrollmentConditions) || _.some(userEnrollmentConditions, ['conditionAccepted', false]))
);

// SystemConfig
export const selectSystemConfig = createSelector(
  selectAuthStatusState,
  (state: State): ClientConfig => state.config
);

export const selectSmartIdLogoutUrl = createSelector(
  selectSystemConfig,
  (clientConfig: ClientConfig) => clientConfig.smartIdLogoutUrl
);

export const selectIsExternalUser = createSelector(
  selectIsInternalUser,
  (isInternalUser: boolean): boolean => !isInternalUser
);

export const selectIsGateKeeperUser = createSelector(
  selectProjectRole,
  (projectRole: string): boolean => projectRole === 'GateKeeper'
);

export const selectCanModifyGatekeeper = createSelector(
  selectIsInternalUser,
  selectIsGateKeeperUser,
  selectPermission(PermissionType.USER_CREATION),
  (isInternalUser: boolean, isGateKeeperUser: boolean, userCreationPermission: Permission): boolean =>
    (isInternalUser || isGateKeeperUser) && userCreationPermission === Permission.FULL
);

export const selectCanInternalUserModify = createSelector(
  selectIsInternalUser,
  selectPermission(PermissionType.USER_CREATION),
  (isInternalUser: boolean, userCreationPermission: Permission): boolean => isInternalUser && userCreationPermission !== Permission.FULL
);

export const selectIsGateKeeperRoleHidden = createSelector(
  selectIsExternalUser,
  selectIsGateKeeperUser,
  (isExternalUser: boolean, isGateKeeperUser: boolean): boolean => isExternalUser && !isGateKeeperUser
);

export const hasAccountCodeConfigSegments = createSelector(
  selectAccountCodeConfigSegments,
  (segments: AccountCodeSegmentConfig[]): boolean => !!segments.length
);

export const selectIsAddDayTypeEnabled = createSelector(
  selectSystemConfig,
  selectProjectSettings,
  ({addDayTypeClientIds}, {clientId}): boolean => _.includes(addDayTypeClientIds, clientId)
);

export const selectDocumentCategories = createSelector(
  selectSystemConfig,
  ({documentCategories}: ClientConfig): { name: string; value: string }[] => documentCategories ?? []
);

export const selectProjectRegion = createSelector(
  selectSelectedProject,
  (project: Project) => {
    return _.get(project, 'regionCode', Region.USA);
  }
);

export const selectBatchUiColumns = createSelector(
  selectProjectRegion,
  (region: Region) => BatchBrowseConstants.BatchBrowseUiColumns[region]
);

export const selectHideTcFooterButtons = createSelector(
  selectProjectPermissions,
  selectComputedWorkflowEnabled,
  (projectPermissions: ProjectPermissions, isWorkflowEnabled: boolean) => {
    const email = _.get(projectPermissions, 'email', '');
    if (_.includes(email, '@ep.com')) {
      return true;
    }
    return (!isWorkflowEnabled && !UserIsInRole('Payroll Accountant', projectPermissions));
  }
);

export const selectDayCostReportChunkSize = createSelector(
  selectSystemConfig,
  selectProjectSettings,
  ({dayCostReportChunkSize}) => dayCostReportChunkSize
);

export const selectIsSagEnabled = createSelector(
  selectSystemConfig,
  selectProjectSettings,
  ({sagEnabledClientIds}, {clientId}): boolean => _.includes(sagEnabledClientIds, clientId)
);
export const selectUserDepartments = createSelector(
  selectUser,
  (user: User): Department[] => user && user.departments
);

export const selectShowSecurityTab = createSelector(
  selectSystemConfig,
  ({showSecurityTab}): boolean => showSecurityTab
);

export const selectVaClientUrl = createSelector(
  selectSystemConfig,
  (clientConfig: ClientConfig) => clientConfig.vaClientUrl
);
